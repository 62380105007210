<script setup lang="ts">
import BasicLayout from '@/components/common/BasicLayout.vue';
import Steps from '@/components/common/Steps.vue';
import { ref, onMounted, inject, computed } from 'vue';
import {
  MContainer,
  MRow,
  MCol,
  MPanel,
  MText,
  MDivider,
  MButton,
  MSpinner,
} from '@ca-crowdfunding/makuake-ui-n';
import router from '@/router';
import {
  TranService,
  ErrorCode,
  PaymentType,
  ProjectFundingType,
  type TranExecRequest,
  type TranTemporaryResponse,
  type TranExecResponse,
  type Error,
  type Project,
  type Item,
  type Payment,
  type Shipping,
  type Totals,
  Ticket,
} from '@/generated/payment-api';
import { paymentTypeMap, cvsCodeMap } from '@/consts';
import type { AxiosError } from 'axios';
import { type errorStore, errorStoreKey } from '@/store/error';
import { isTimeoutError } from '@/generated/payment-api/core/request';
import { isDate } from '@/utils/date';

const store = inject(errorStoreKey) as errorStore;
const isLoadingContents = ref<boolean>(true);
const isLoadingButton = ref<boolean>(false);
const csrfToken = ref('');
const project = ref<Project | null>(null);
const item = ref<Item | null>(null);
const payment = ref<Payment | null>(null);
const shipping = ref<Shipping | null>(null);
const totals = ref<Totals | null>(null);
const ticket = ref<Ticket | undefined>(undefined);
const termUrl = import.meta.env.VITE_MAKUAKE_URL + '/pages/term/';
const showReturnText = ref<boolean>(false);

// 表示用にフォーマットされた支払い期限
const formattedPaymentDueDate = computed(() => {
  const date = paymentDueDate.value;
  return `${date.getFullYear()}年${date.getMonth() + 1}月${date.getDate()}日`;
});
// 支払い期限を計算
const paymentDueDate = computed(() => {
  const today = new Date();
  const sixDaysLater = new Date(today);
  sixDaysLater.setDate(today.getDate() + 6);
  const expirationDateString = project.value?.expiration_date ?? '';
  if (expirationDateString.length === 0) {
    return sixDaysLater;
  }
  const expirationDate = new Date(expirationDateString);
  // プロジェクト終了日が通常の支払い期日以前の場合、プロジェクト終了日となる
  return expirationDate < sixDaysLater ? expirationDate : sixDaysLater;
});

const formatExpirationDate = computed(() => {
  const defaltText = 'プロジェクト終了日まで申込できます';
  const date = project.value?.expiration_date;
  if (date === undefined) {
    return defaltText;
  }
  const d = new Date(date);
  if (isDate(d)) {
    return defaltText;
  }
  return (
    'プロジェクト終了日の' +
    d.getFullYear() +
    '年' +
    (d.getMonth() + 1) +
    '月' +
    d.getDate() +
    '日まで申込できます'
  );
});
const formatPaymentType = computed(() => {
  if (payment.value?.payment_type === undefined) {
    return '';
  }
  if (payment.value?.payment_type === PaymentType.CVS) {
    const cvsCode = payment.value?.cvs_code;
    if (cvsCode === undefined) {
      return '';
    }
    // コンビニ決済の場合はコンビニ名も表示する
    return `${paymentTypeMap.get(payment.value.payment_type)}（${cvsCodeMap.get(
      cvsCode,
    )}）`;
  }
  return paymentTypeMap.get(payment.value.payment_type);
});
const formatDeliveryDate = computed(() => {
  const parts = shipping.value?.delivery_date?.split('-');
  if (parts === undefined) {
    return '';
  }
  if (parts.length < 2) {
    return '';
  }
  return `${parts[0]}年${parts[1]}月末`;
});
const formatPostalCode = computed(() => {
  const postalCode = shipping.value?.postal_code ?? '';
  return `〒${postalCode.slice(0, 3)}-${postalCode.slice(3)}`;
});

const appliedTicketLabel = computed(() => {
  if (!ticket.value) {
    return '適用なし';
  }

  let symbol = '円';

  if (ticket.value?.discount_type === Ticket.discount_type.RATE) {
    symbol = '%';
  }

  return `${ticket.value?.amount}${symbol}OFFクーポンを適用中`;
});

const itemPriceLabel = computed(() => {
  return project.value?.is_donative ? '寄附金額' : '応援購入総額';
});

const isShowCoupon = computed(() => {
  return !project.value?.is_donative;
});

const toggleReturnText = () => {
  showReturnText.value = !showReturnText.value;
};

onMounted(() => {
  if (store.code !== '') {
    router.push(`/error`);
    return;
  }
  TranService.tranTemporary()
    .then((response: TranTemporaryResponse) => {
      csrfToken.value = response.csrf_token;
      project.value = response.project;
      item.value = response.item;
      payment.value = response.payment;
      shipping.value = response.shipping;
      totals.value = response.totals;
      ticket.value = response.ticket;
      isLoadingContents.value = false;
    })
    .catch((error: AxiosError<Error, any>) => {
      if (!error.response) {
        isTimeoutError(error, store, false) ? router.push(`/error`) : null;
        return;
      }
      const { status } = error.response;
      const { code, message } = error.response.data;
      if (status === 401 && code === ErrorCode.AUTHENTICATION_ERROR) {
        window.location.assign(import.meta.env.VITE_MAKUAKE_URL + '/login/');
        return;
      }
      store.code = code;
      store.messages = [message];
      router.push(`/error`);
    });
});

const handleNegative = () => {
  isLoadingButton.value = true;
  router.go(-1);
};

const handlePositive = () => {
  isLoadingButton.value = true;
  const body: TranExecRequest = {
    csrf_token: csrfToken.value,
  };
  TranService.tranExec(body)
    .then((response: TranExecResponse) => {
      window.location.assign(response.redirect_url);
    })
    .catch((error: AxiosError<Error, any>) => {
      isLoadingButton.value = false;
      if (!error.response) {
        isTimeoutError(error, store, true) ? router.push(`/error`) : null;
        return;
      }
      const { status } = error.response;
      const { code, message } = error.response.data;

      if (status === 401 && code === ErrorCode.AUTHENTICATION_ERROR) {
        window.location.assign(import.meta.env.VITE_MAKUAKE_URL + '/login/');
        return;
      }
      store.code = code;
      store.messages = [message];
      router.push(`/error`);
    });
};

const calculateItemExplanationCol = computed(() => {
  if (item.value?.is_image) {
    return 8;
  }
  return 12;
});
</script>

<template>
  <BasicLayout>
    <div class="confirm-wrapper">
      <MDivider
        color="tertiary"
        :rounded="false"
        tag="hr"
        thickness="1"
        :vertical="false"
        class="order-detail-line"
      />
      <Steps :step="3" class="step" />
      <div v-if="isLoadingContents" class="body-contets">
        <MSpinner size="medium" />
      </div>
      <MContainer
        :fluid="true"
        :padding="0"
        class="confirm-body"
        v-if="!isLoadingContents"
      >
        <MRow gap="0" class="order-detail">
          <MCol cols="12" sm="8">
            <div class="page-title">
              <MText size="3x-large" sm="4x-large" weight="bold"
                >申込内容の確認</MText
              >
              <MText
                class="order-subtitle"
                size="x-small"
                sm="small"
                color="secondary"
                >間違いがないか確認し、申込を確定するボタンを押してください。</MText
              >
            </div>
            <MDivider
              color="secondary"
              :rounded="false"
              tag="hr"
              thickness="1"
              :vertical="false"
              class="order-detail-line"
            />
            <MContainer :fluid="true" :padding="0">
              <MRow gap="0">
                <MCol cols="12" sm="12">
                  <MContainer :fluid="true" :padding="0">
                    <div class="item">
                      <MRow gap="0">
                        <MCol cols="4" v-if="item?.is_image" class="image-col">
                          <!-- TODO: MImgでは画像が表示されなかったので暫定的にimgタグを使用 -->
                          <img :src="item?.image_path" class="item-image" />
                        </MCol>
                        <MCol :cols="calculateItemExplanationCol">
                          <MText
                            :class="
                              item?.is_image
                                ? 'item-title'
                                : 'item-title-without-image'
                            "
                            size="medium"
                            sm="large"
                            weight="bold"
                            >{{ item?.title }}</MText
                          >
                          <!-- TODO: 要リファクタ -->
                          <!-- レスポンシブに動かす方法がすぐに分からなかったのでdisplaay: noneを効かせて対応-->
                          <div class="pc-only">
                            <MText
                              v-if="showReturnText"
                              :class="
                                item?.is_image
                                  ? 'item-detail'
                                  : 'item-detail-without-image'
                              "
                              size="2x-small"
                              sm="small"
                              color="secondary"
                              >{{ item?.detail }}</MText
                            >
                            <!-- TODO: 要リファクタ -->
                            <!-- 本来2行指定だが一旦50文字で切り詰めて対応 -->
                            <MText
                              v-if="!showReturnText"
                              :class="
                                item?.is_image
                                  ? 'item-detail'
                                  : 'item-detail-without-image'
                              "
                              size="2x-small"
                              sm="small"
                              color="secondary"
                              >{{ item?.detail.substr(0, 50)
                              }}<span
                                class="show-more"
                                size="2x-small"
                                sm="small"
                                weight="bold"
                                color="secondary"
                                v-on:click="toggleReturnText"
                                >...もっと見る</span
                              >
                            </MText>
                          </div>
                        </MCol>
                        <!-- TODO: 要リファクタ -->
                        <!-- レスポンシブに動かす方法がすぐに分からなかったのでdisplaay: noneを効かせて対応-->
                        <MCol cols="12" class="sp-only">
                          <MText
                            v-if="showReturnText"
                            class="item-detail"
                            size="2x-small"
                            sm="small"
                            color="secondary"
                            >{{ item?.detail }}</MText
                          >
                          <!-- TODO: 要リファクタ -->
                          <!-- 本来2行指定だが一旦50文字で切り詰めて対応 -->
                          <MText
                            v-if="!showReturnText"
                            class="item-detail"
                            size="2x-small"
                            sm="small"
                            color="secondary"
                            >{{ item?.detail.substr(0, 50) }}
                            <span
                              class="show-more"
                              size="2x-small"
                              sm="small"
                              weight="bold"
                              color="secondary"
                              v-on:click="toggleReturnText"
                              >...もっと見る</span
                            >
                          </MText>
                        </MCol>
                      </MRow>
                      <MRow gap="0" v-if="item?.questions.length != 0">
                        <MCol cols="3" sm="2">
                          <MText
                            class="order-detail-title"
                            size="small"
                            sm="medium"
                            weight="bold"
                            >質問と回答</MText
                          >
                        </MCol>
                        <MCol cols="9" sm="10">
                          <MText
                            class="order-detail-text"
                            size="small"
                            sm="medium"
                            v-for="qa in item?.questions"
                            :key="qa.question"
                            >{{ qa.question }} : {{ qa.answer }}</MText
                          >
                        </MCol>
                      </MRow>
                      <MRow gap="0">
                        <MCol cols="3" sm="2">
                          <MText
                            class="order-detail-title"
                            size="small"
                            sm="medium"
                            weight="bold"
                            >申込期限</MText
                          >
                        </MCol>
                        <MCol cols="9" sm="10">
                          <MText
                            class="order-detail-text"
                            size="small"
                            sm="medium"
                            >{{ formatExpirationDate }}</MText
                          >
                        </MCol>
                      </MRow>
                    </div>
                    <MDivider
                      color="secondary"
                      :rounded="false"
                      tag="hr"
                      thickness="1"
                      :vertical="false"
                      class="order-detail-line"
                    />
                    <div class="shipping">
                      <MRow gap="0">
                        <MCol cols="3" sm="2">
                          <MText
                            class="order-detail-title"
                            size="small"
                            sm="medium"
                            weight="bold"
                            >お届け予定</MText
                          >
                        </MCol>
                        <MCol cols="9" sm="10">
                          <MText
                            class="order-detail-text"
                            size="small"
                            sm="medium"
                            >{{ formatDeliveryDate }}</MText
                          >
                          <MText size="3x-small" sm="x-small" color="secondary"
                            >※通常のECサイトとは異なり、応援購入後の配送までに数ヶ月要します。</MText
                          >
                        </MCol>
                      </MRow>
                      <MRow gap="0">
                        <MCol cols="3" sm="2">
                          <MText
                            class="order-detail-title"
                            v-if="shipping?.has_shipping"
                            size="small"
                            sm="medium"
                            weight="bold"
                            >お届け先</MText
                          >
                          <MText
                            class="order-detail-title"
                            v-else
                            size="small"
                            sm="medium"
                            weight="bold"
                            >申込者情報</MText
                          >
                        </MCol>
                        <MCol cols="9" sm="10">
                          <MText
                            class="order-detail-text"
                            size="small"
                            sm="medium"
                            >{{ shipping?.name }}
                            {{ shipping?.phone_number }}</MText
                          >
                          <MText
                            class="order-shipping-text"
                            size="small"
                            sm="medium"
                            v-show="shipping?.has_shipping"
                            >{{ formatPostalCode }}
                            {{ shipping?.address }}</MText
                          >
                        </MCol>
                      </MRow>
                    </div>
                    <MDivider
                      color="secondary"
                      :rounded="false"
                      tag="hr"
                      thickness="1"
                      :vertical="false"
                      class="order-detail-line"
                    />
                    <div class="ticket" v-show="isShowCoupon">
                      <MRow gap="0">
                        <MCol cols="4" sm="2">
                          <MText
                            class="order-detail-title"
                            size="small"
                            sm="medium"
                            weight="bold"
                            >応援クーポン</MText
                          >
                        </MCol>
                        <MCol cols="8" sm="10">
                          <MText
                            class="order-detail-text"
                            size="small"
                            sm="medium"
                            >{{ appliedTicketLabel }}</MText
                          >
                        </MCol>
                      </MRow>
                    </div>
                    <MDivider
                      color="secondary"
                      :rounded="false"
                      tag="hr"
                      thickness="1"
                      :vertical="false"
                      class="order-detail-line"
                      v-show="isShowCoupon"
                    />
                  </MContainer>
                </MCol>
              </MRow>
            </MContainer>
          </MCol>
          <MCol cols="12" sm="4">
            <MContainer :fluid="true" :padding="0" class="payment-info">
              <MRow gap="0">
                <MCol cols="12" sm="12">
                  <MText
                    class="payment-method"
                    align="center"
                    size="x-small"
                    sm="small"
                    weight="bold"
                    color="secondary"
                  >
                    支払い方法: {{ formatPaymentType }}
                  </MText>
                </MCol>
              </MRow>
              <MRow gap="0">
                <MCol cols="6" sm="6">
                  <MText class="payment-info-title" size="small" sm="medium">{{
                    itemPriceLabel
                  }}</MText>
                </MCol>
                <MCol cols="6" sm="6">
                  <MText
                    class="payment-info-text"
                    size="small"
                    sm="medium"
                    align="right"
                    >{{ totals?.item_price.toLocaleString() }}円</MText
                  >
                </MCol>
              </MRow>
              <MRow gap="0">
                <MCol cols="6" sm="6">
                  <MText class="payment-info-text" size="small" sm="medium"
                    >安心システム利用料</MText
                  >
                </MCol>
                <MCol cols="6" sm="6">
                  <MText
                    class="payment-info-text"
                    size="small"
                    sm="medium"
                    align="right"
                    >{{ totals?.supporter_usage_fee.toLocaleString() }}円</MText
                  >
                </MCol>
              </MRow>
              <MRow gap="0" v-if="ticket">
                <MCol cols="6" sm="6">
                  <MText class="payment-info-text" size="small" sm="medium"
                    >応援クーポン割引額</MText
                  >
                </MCol>
                <MCol cols="6" sm="6">
                  <MText
                    class="payment-info-text-discount"
                    size="small"
                    sm="medium"
                    align="right"
                    >-{{
                      totals?.ticket_discount_amount.toLocaleString()
                    }}円</MText
                  >
                </MCol>
              </MRow>
              <MRow gap="0">
                <MCol cols="6" sm="6">
                  <MText class="payment-info-text" size="small" sm="medium"
                    >支払い総額（税込）</MText
                  >
                </MCol>
                <MCol cols="6" sm="6">
                  <MText
                    class="payment-info-text-total"
                    size="large"
                    sm="x-large"
                    weight="bold"
                    align="right"
                    >{{ totals?.payment_price.toLocaleString() }}円</MText
                  >
                </MCol>
              </MRow>
              <MRow gap="0">
                <MCol cols="12" sm="12" style="text-align: center">
                  <MSpinner v-if="isLoadingButton" size="medium" />
                  <MButton
                    :block="true"
                    class="payment-info-confirmed-button"
                    v-if="!isLoadingButton"
                    v-on:click="handlePositive"
                  >
                    <MText size="x-large" color="white" weight="bold">
                      申込を確定する
                    </MText>
                  </MButton>
                </MCol>
              </MRow>
              <MRow gap="0">
                <MCol cols="12" sm="12" style="text-align: center">
                  <MButton
                    class="payment-info-text-back"
                    variant="text"
                    v-if="!isLoadingButton"
                    v-on:click="handleNegative"
                  >
                    <MText size="medium"> 戻る </MText>
                  </MButton>
                </MCol>
              </MRow>
              <MRow gap="0">
                <MCol cols="12" sm="12"> </MCol>
              </MRow>
            </MContainer>
            <MText class="stock-info" size="x-small" color="secondary">
              ※在庫は「申込を確定する」ボタン押下から15分間確保されます。所定時間内に注文手続きが完了されない場合は在庫は解放されますのでご注意ください。
            </MText>
          </MCol>
        </MRow>
        <MRow gap="0">
          <MCol cols="12" sm="8">
            <MPanel class="cancel-info" color="current">
              <MText size="small" sm="medium" weight="bold">
                キャンセル等について
              </MText>
              <MText size="x-small" sm="small">
                ※原則として、決済完了後にご自身の都合によるキャンセルは受け付けておりません。
              </MText>
              <MText size="x-small" sm="small">
                ※リターン提供の遅れに基づくキャンセルに関してはプロジェクトページの「リスク&チャレンジ」の項目をご覧ください。
              </MText>
              <MText size="x-small" sm="small">
                ※プロジェクトやリターンの内容、返金、返品等に関するお問い合わせは、プロジェクト実行者へ直接お問い合わせ願います。
              </MText>
              <MText size="x-small" sm="small">
                詳細については<MText
                  size="x-small"
                  sm="small"
                  color="info"
                  :href="termUrl"
                  target="_blank"
                  >利用規約</MText
                >をご覧ください。</MText
              >
              <MText class="pad1" size="small" sm="medium" weight="bold">
                支払い期限等の詳細について
              </MText>
              <div v-if="payment?.payment_type == PaymentType.CARD">
                <MText
                  class="cancel-text"
                  size="x-small"
                  sm="small"
                  v-if="project?.type == ProjectFundingType.ALL_IN"
                >
                  クレジットカードでの前払となり、リターンの注文受付完了時に課金されます。
                </MText>
                <MText class="cancel-text" v-else size="x-small" sm="small">
                  クレジットカードでの前払となり、リターンの注文受付完了後、目標金額達成時に課金されます。リターンの注文受付完了時に既に目標金額を達成している場合は、リターン受付完了時に課金されます。
                </MText>
              </div>
              <div
                v-if="
                  payment?.payment_type == PaymentType.CVS ||
                  payment?.payment_type == PaymentType.PAYEASY ||
                  payment?.payment_type == PaymentType.GANB
                "
              >
                <MText size="x-small" sm="small">
                  前払いとなり、注文が確定され次第、お支払い番号を発行し、Eメールでお知らせしますので、<span
                    class="font-bold"
                    >【{{ formattedPaymentDueDate }}】</span
                  >までに代金をお支払いください。
                </MText>
                <MText size="x-small" sm="small">
                  ※銀行振込は、特定の時間を過ぎると相手口座への反映が翌営業日以降となります。
                </MText>
                <MText size="x-small" sm="small">
                  ※お支払い期限を過ぎますとご注文が自動的にキャンセルとなりますのでご注意ください。なお、所定の手数料を頂戴します。
                </MText>
              </div>
            </MPanel>
          </MCol>
        </MRow>
      </MContainer>
    </div>
  </BasicLayout>
</template>

<style scoped>
.body-contets {
  background-color: #ffffff;
  width: 100%;
  height: 100%;
}
.confirm-wrapper {
  background-color: #ffffff;
  color: #222222;
  width: 100%;
}
.confirm-body {
  line-height: 1.5em;
  max-width: 1300px;
  margin: auto;
}
.step {
  margin-top: 2em;
}
.page-title,
.item,
.shipping,
.ticket {
  padding: 0 1.5rem;
}
.order-subtitle {
  padding: 1.5em 0 1em 0;
}
.image-col {
  /* TODO: 本来のデザインはpc版が画像とタイトルのtop部分の高さを揃える、spはタイトルと画像の中心線を揃える形だが、時間がないので画像とタイトルの高さをなんとなく揃えてある */
  padding-top: 0.5rem;
}
.item-image {
  display: block;
  margin-right: 1em;
  border-radius: 8px;
}
.item-title {
  padding: 1rem 0 0 1rem;
}
.item-title-without-image {
  padding: 1rem 0 0 0;
}
.order-detail-title {
  padding: 1em 0;
}
.order-detail-text {
  padding: 1em 0;
  word-wrap: break-word;
}
.item-detail {
  padding: 1rem 0 0 0;
  word-wrap: break-word;
  white-space: pre-wrap;
}
/* TODO ブレークポイントの調査と設定*/
@media screen and (min-width: 768px) {
  .item-detail {
    padding: 1rem 0 0 1rem;
  }
}
.item-detail-without-image {
  padding: 1rem 0 0 0;
  word-wrap: break-word;
  white-space: pre-wrap;
}
.order-shipping-text {
  padding: 0 0 1em 0;
  word-wrap: break-word;
}
.order-detail-line {
  margin: 0.5em 0;
}
.show-more {
  font-size: 11px;
  font-weight: bold;
}
@media screen and (min-width: 768px) {
  .show-more {
    font-size: 14px;
  }
}
.payment-info {
  padding: 1.5rem;
}
/* TODO ブレークポイントの調査と設定*/
@media screen and (min-width: 768px) {
  .payment-info {
    padding: 1em 2em;
    border-radius: 8px;
    background-color: #f5f5f5;
    margin-left: 40px;
  }
}
.payment-method {
  background-color: #f5f5f5;
  padding: 1rem 3rem;
  margin-bottom: 1em;
  border-radius: 8px;
}
@media screen and (min-width: 768px) {
  .payment-method {
    background-color: #ededed;
  }
}
.payment-info-title {
  padding: 0.7em 0 0.7em 0;
}
.payment-info-text {
  padding: 0.7em 0 0.7em 0;
}
.payment-info-text-discount {
  padding: 0.7em 0 0.7em 0;
  color: #f53a67;
}
.payment-info-text-total {
  padding: 0.7em 0 0.7em 0;
}
.payment-info-confirmed-button {
  background-color: #f7c600;
  height: 3em;
  margin-top: 0.7em;
}
.payment-info-text-back {
  padding: 0.5em 0 0.5em 0;
  margin-top: 0.7em;
}

.stock-info {
  padding: 0px 1.5rem;
  line-height: 1.5rem;
}
/* TODO ブレークポイントの調査と設定*/
@media screen and (min-width: 768px) {
  .stock-info {
    width: 100%;
    margin: 1.5rem 0 0 40px;
    padding: 0;
  }
}
.cancel-info {
  margin: 0 1.5rem;
  padding: 1.25rem;
  background-color: #f5f5f5;
  border-radius: 8px;
  margin-top: 2em;
}
/* TODO ブレークポイントの調査と設定*/
@media screen and (min-width: 768px) {
  .cancel-info {
    margin: 20px 0 40px 0;
  }
}
.pad1 {
  padding-top: 1em;
}
.font-bold {
  font-weight: bold;
}
/* TODO ブレークポイントの調査と設定*/
@media screen and (min-width: 768px) {
  .sp-only {
    display: none;
  }
}

/* TODO ブレークポイントの調査と設定*/
@media screen and (max-width: 768px) {
  .pc-only {
    display: none;
  }
}
</style>
