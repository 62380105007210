/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type Ticket = {
    /**
     * 割引タイプ
     */
    discount_type: Ticket.discount_type;
    /**
     * 割引額
     */
    amount: number;
};
export namespace Ticket {
    /**
     * 割引タイプ
     */
    export enum discount_type {
        FIXED = 'FIXED',
        RATE = 'RATE',
    }
}

