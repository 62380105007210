/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * プロジェクトタイプ
 */
export enum ProjectFundingType {
    ALL_IN = 'ALL_IN',
    ALL_OR_NOTHING = 'ALL_OR_NOTHING',
}
